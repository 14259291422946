@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@300&display=swap');

.header-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-grow: 1;
    padding: 0% 0%;
}

.display-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin: 5% 5%;
}

h1 {
    display: flex;
    text-align: start;
    color: #41a344;
    margin: 0px;
    padding: 0% 5%;
    font-size: 30px;
    font-weight: lighter;
    font-family: 'Source Code Pro';
}

img {
    width: 15%;
    height: 15%;
}

.logo {
    border-radius: 50%;
}