
@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@300&display=swap');

.skill-container {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 100%;
    padding: 1% 0%;
    margin: 0%;
}

.paper-container {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    background-color: #2c2c2c;
    padding: 1% 1%;
    margin: 1% 1%;
    border-radius: 3%;
}

.chip-container {
    display: flex;
    flex-wrap: wrap;
}

.title {
    display: flex;
    justify-content: flex-start;
    color: beige;
    font-family: 'Source Code Pro';
    font-size: 1.5rem;
}

.partition {
    margin: 10px 0px;
    background-color: #3d3d3d;
}

.chip-label {
    display: flex;
    margin: 1%;
    background-color: #3d3d3d;
    color:#9a9a9a;
    font-family: 'Source Code Pro';
}
