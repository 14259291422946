@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@300&display=swap');

.certification-container {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    width: 100%;
    padding: 1% 0%;
}

.grid {
    margin: 1% 0%;
}

.card-container {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    background-color: #2c2c2c;
    margin: 1%;
    padding: .5%;
    border-radius: 3%;
}

.data {
    display: flex;
    justify-content: center;
    color: beige;
    font-family: 'Source Code Pro';
    font-size: 1.2rem;
}

.partition {
    margin: 10px 0px;
    background-color: #3d3d3d;
}

.expiry {
    display: flex;
    justify-content: center;
    color: beige;
    font-family: 'Source Code Pro';
    font-size: .7rem;
}