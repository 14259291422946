@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@300&display=swap');

.edu-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
}

/* apply same font all the way */
.font * {
    font-family: 'Source Code Pro';
}

.heading {
    color: #41a344;
    font-size: 2rem;
    font-weight: bolder;
}

.timeline *{
    color: beige;
}

.bold {
    font-weight: bolder;
    font-size: 1.5rem;
}

.connector, .dot {
    background-color: #41a344;
}