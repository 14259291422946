@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@300&display=swap');

.tab {
    color: #41a344;
    margin: 0px;
    padding: 1%;
    font-size: x-large;
    font-weight: lighter;
    font-family: 'Source Code Pro';
}

.tab.Mui-selected {
    color: beige;
}

.text-lowercase {
    text-transform: lowercase;
}