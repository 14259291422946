@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@300&display=swap');

.copyright {
    display: flex;
    font-family: 'Source Code Pro';
    font-size: 2rem;
    color: beige;
}

.connect {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.icon {
    margin: 1rem;
    color: beige;
}

.icon:hover {
    color: #41a344;
    transition: 0.3s ease-out;
}